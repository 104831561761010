(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name settings.controller:SettingsCtrl
   *
   * @description
   *
   */
  angular
    .module('settings')
    .controller('SettingsCtrl', SettingsCtrl);

  function SettingsCtrl() {
    var vm = this;
    vm.ctrlName = 'SettingsCtrl';
  }
}());
